import "./App.css";
import { Component } from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
};

class App extends Component {

  interval = null;

  constructor(props) {
    super(props);
    var pdfCode = window.location.href.replace(window.location.origin, "");
    pdfCode = pdfCode.replace("/", "");

    this.state = {
      pdfCode: pdfCode,
      pdfInfo: [],
      ready: false,
      pdf: '',
      numPages: null,
      pageNumber: 1,
      thisSession: '',
      needPassword: false,
      password: '',
    };

  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  }

  componentDidMount() {
    if(this.state.pdfCode != ''){
      this.getPDFInfo(this.state.pdfCode, '');
    }else{
      this.goToHome();
    }
    
   
  }


  getPDFInfo = (pdfCode, password) => {
    this.setState({ needPassword: false });
    const requestOptions = {
      method: "POST",
      headers: new Headers({
        "Accept": "application/x-www-form-urlencoded;charset=UTF-8",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      }),
      body: JSON.stringify({
        code: pdfCode,
        password: password
      }),
    };

    const apiUrl = "https://p-d-f.link/api/user/v2/pdf/code";

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
              .then(r => {

       if(r.status == 'need_password'){
   
        this.setState({ needPassword: true });

       }else{

      this.interval = setInterval(() => {const requestOptions = {
        method: "POST",
        headers: new Headers({
          "Accept": "application/x-www-form-urlencoded;charset=UTF-8",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        }),
        body: JSON.stringify({
          session: this.state.thisSession,
        }),
      };
      
      

      const apiUrl = "https://p-d-f.link/api/user/v2/pdf/time";
  
      fetch(apiUrl, requestOptions);} , 5000);

      
      document.title = r.pdf.name;
      this.setState({ thisSession: r.session, pdf: r.pdf.url, ready: true });
    }

      })
      .catch((e) => {
        this.setState({ error: true });

        return;
      });
  }

  goToHome = () => {
    this.setState({ showBlog: true });
   // window.location.replace('https://p-d-f.link/home/');
  }

  insertPassword = (e) => {
    var insert = String(e.target.value);      
    this.setState({ password: insert, showError: ''});     
}

confirmPassword = () => {
  if(this.state.password.length > 0){
    this.getPDFInfo(this.state.pdfCode, this.state.password);
  }
}


  render() {
    return (
      <div className="App">
        {this.state.needPassword &&
        <div><div  style={{marginTop: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
        backgroundColor: 'white'}}>
        <div>
        <input 
        style={{ paddingLeft: '15px', border: 'none', color: 'rgb(50,50,50)', fontWeight: 'bold', height: '40px', width: '240px', borderRadius: '8px', 
        backgroundColor: 'white', outlineWidth: '0', fontSize: '12px'}} type="password"  
        name="name"
        autoComplete="off"
        value={this.state.password}
        placeholder="Password"
        onChange={this.insertPassword}
        />
        </div>
        </div>
        </div>
        <div  style={{ marginTop: '10px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
            <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '35px', width: '120px', borderRadius: '5px', backgroundColor: 'gray', fontSize: '12px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirmPassword}
            >Submit</p>
            </div>
        </div>}
        {this.state.ready &&
        <div className="Example__container__document">
        <Document file={this.state.pdf} onLoadSuccess={this.onDocumentLoad} options={options}>
        {Array.from(new Array(this.state.numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
        </Document>
       </div>
        }
        {this.state.error &&
        <div>
        <p style={{textAlign:"center", color: 'white', fontSize: '60px', fontWeight: 'bold'}}>404</p>  
        <p style={{textAlign:"center", color: 'white', fontSize: '25px', fontWeight: 'bold'}}>Oooooops, page not found</p>  
        <br /><br />
        <p onClick={this.goToHome} style={{textAlign:"center", color: 'white', cursor: 'pointer'}}>
        Discover more about P-D-F Link
      </p></div>
        }
        {this.state.showBlog && <div style={{ backgroundColor: 'white' }}><iframe src="https://p-d-f.link/home/blog/en/how-to-convert-pdf-to-link" height="1000px" width="100%"/> </div>  
  }
      </div>
    );
  }
}

export default App;
